import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { translations } from "./config/translations/translations";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadChildren: () => import("./gridconnection/base.module").then(m => m.BaseModule),
  },
  {
    path: "capacity-request",
    loadChildren: () => import("./capacity-request/capacity-request.module").then(m => m.CapacityRequestModule),
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
