import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class InitializerProvider {

  constructor(private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcTranslationService: BfcTranslationService,
  ) {

  }

  public initialize() {
    this.bfcGoogleAnalyticsService
      .initGoogleAnalytics(this.bfcConfigurationService.configuration.googleAnalyticsTrackingId);
    this.bfcGoogleAnalyticsService
      .initGoogleTagManager(this.bfcConfigurationService.configuration.googleTagManagerContainerId);
    const contactUrl = this.bfcConfigurationService.configuration.contactUrl;
    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    const serviceItemsModify = JSON.stringify([
      {
        key: "contact",
        titleTranslatable: this.bfcTranslationService.translate("SERVICE_NAV.CONTACT"),
        url: contactUrl,
      },
    ]);

    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);
    } else {
      throw (new Error("too many or no global navigation element found"));
    }
    const footerElements = document.getElementsByTagName("bfe-footer");
    if (footerElements.length === 1) {
      footerElements[0].setAttribute("contact-url", JSON.stringify(contactUrl));
    } else {
      throw (new Error("too many or no footer element found"));
    }

  }
}