import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "hasValue",
})
export class HasValuePipe implements PipeTransform {
  transform(value: string | number): boolean {
    return !HasValuePipe.isEmpty(value);
  }

  private static isEmpty(value: string | number): boolean {
    return value === null || value === undefined || value === "" || value === "null";
  }
}
