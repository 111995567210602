import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, of } from "rxjs";
import { GridStatusResponse } from "../model/grid-status-response";

@Injectable()
export class GridService {

  constructor(private httpClient: HttpClient,
    private bfcConfig: BfcConfigurationService) {
  }

  public fetchStatus(aoid: string): Observable<GridStatusResponse> {
    if (!aoid) {
      return of();
    }
    const params = { aoid };
    const headers = { "Accept": "application/json" };
    return this.httpClient.get<GridStatusResponse>(this.bfcConfig.configuration.gridServiceApiUrl, {
      params,
      headers,
    });
  }

}
