import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-color-box-layout",
  templateUrl: "color-box-layout.component.html",
  styleUrls: ["color-box-layout.component.scss"],
})

export class ColorBoxLayoutComponent {

  @HostBinding("class")
  private classes = "gc-cb-layout";

  @Input()
  colorClass: string;

  @Input()
  bgStripes: boolean;

}
