import { Pipe, PipeTransform } from "@angular/core";
import { HasValuePipe } from "../is-empty-pipe/has-value.pipe";

@Pipe({
  name: "emptyDefault",
})
export class EmptyDefaultPipe implements PipeTransform {
  constructor(private hasValuePipe: HasValuePipe) {}

  transform(value: any, defaultValue: string = "-"): string {
    return (this.hasValuePipe.transform(value)) ? value : defaultValue;
  }
}
