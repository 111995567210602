import { IBfcConfiguration } from "@bfl/components/configuration";
import { IBfcTranslatable } from "@bfl/components/translation";
import { GENERAL_ENV } from "./enviroments/general";
import { LOCAL_ENV } from "./enviroments/local";
import { T_ENV } from "./enviroments/t";
import { Q_ENV } from "./enviroments/q";
import { P_ENV } from "./enviroments/p";

export const configuration: IBfcConfiguration[] = [{
  theme: "bkw",
  general: GENERAL_ENV,
  local: LOCAL_ENV,
  t: T_ENV,
  q: Q_ENV,
  p: P_ENV,
}];

export interface AppConfiguration {
  companyName?: string;
  googleAnalyticsTrackingId?: string;
  googleTagManagerContainerId?: string;
  pdfDateFormat?: string;
  gridConnectionRequestUrl?: string;
  contactUrl?: IBfcTranslatable;
  sapApiUrls?: SapApiUrls;
  apiUrl?: string;
  gridServiceApiUrl?: string,
  installerApiUrl?: string,
  roleMyBkw?: string,
  roleCapacityRequest?: string,
}

export interface SapApiUrls {
  servicesNetz: string;
}
