import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { Moment } from "moment";
import { TechInstallationSet } from "../../model/service-netz/tech-installation-set";
import { TechPowerAssignedSet } from "../../model/service-netz/tech-power-assigned-set";
import { ODataContext } from "./odata-context";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

// Request
const RequestMethod = Object.freeze({
  "Get": 0,
  "Post": 1,
  "Put": 2,
  "Delete": 3,
  "Options": 4,
  "Head": 5,
  "Patch": 6,
});

@Injectable()
export class ODataManagerService {

  // This is a stateful odata context and is initialised on createContext()
  protected context: ODataContext = null;

  private odataVersion: string = "2.0";

  private accept: string = "application/json";

  // Allowed filter operators
  private OPERATOR = {
    "==": "eq",
    "!=": "ne",
    ">": "gt",
    "<": "lt",
  };

  constructor(private _http: HttpClient) {
  }

  /**
     * ENTRYPOINT // The Odata Context object holds state for the current Odata service
     *
     * @param {string} servicePath
     * @param {Headers} headers
     */
  public createContext(servicePath: string, headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    // headers.append("MaxDataServiceVersion", this.odataVersion); // append default header
    headers.append("Accept", this.accept); // append default header
    this.context = new ODataContext(servicePath, headers);
    return this;
  }

  /**
     * Set servicePath
     * @param {string} servicePath
     */
  public setServicePath(servicePath: string) {
    this.context.servicePath = servicePath;
  }

  /**
     * Set optional headers
     * @param {Headers} headers
     */
  public setHeaders(headers: HttpHeaders) {
    this.context.headers = headers;
  }

  /**
     * Do GET Request
     *
     * @param {Object} params
     * @returns {Observable<Body>}
     */
  public query(params?: object): Observable<any> {
    this.context.queryParams = params;
    const options = { headers: this.context.headers };
    let observable = this._http.get(this.createQueryFromContext(RequestMethod.Get, this.context), options);
    return observable;
  }

  /**
     * Do POST Request
     * @returns {Observable<Body>}
     */
  public save(body?: any): Observable<any> {
    const options = { headers: this.context.headers };
    return this._http.post(this.createQueryFromContext(RequestMethod.Post, this.context), body, options);
  }

  /**
     * Do PUT Request
     * @returns {Observable<Body>}
     */
  public update(body?: any): Observable<any> {
    const options = { headers: this.context.headers };
    return this._http.put(this.createQueryFromContext(RequestMethod.Put, this.context), body, options);
  }

  /**
     * Do DELETE Request
     * @returns {Observable<Body>}
     */
  public delete(): Observable<any> {
    const options = { headers: this.context.headers };
    return this._http.delete(this.createQueryFromContext(RequestMethod.Delete, this.context), options);
  }

  /**
     * Base entity to fetch data from
     * @param {string} name
     * @returns {ODataManagerService}
     */
  public entity(name: string): ODataManagerService {
    this.context.entity = name;
    return this;
  }

  /**
     * select single entry only
     *   can be a number (no escaping),
     *   a string (add ' on both sides) or
     *   a combination of parameters, like AccountID='5',BankAccountID='123'
     * @param value
     * @returns {ODataManagerService}
     */
  public single(value: string) {
    if (_.isNumber(value)) {
      this.context.single = "(" + value + ")";
    } else if (value.indexOf("=") === -1) {
      this.context.single = "('" + value + "')";
    } else {
      this.context.single = "(" + value + ")";
    }
    return this;
  }

  /**
     * Add a filter for any type.
     * @param {string} field Name of the field on the OData-API
     * @param {ODataOperator} operator Comparision-operator
     * @param value Value to compare against.
     * @returns {ODataManagerService} Return the class for chaining the calls
     */
  public filter(field: string, operator: string, value: any): ODataManagerService {
    if (_.isBoolean(value) === true) {
      this.context.appendFilterExpression(field + " " + this.OPERATOR[operator] + " " + value);
    } else if (_.isDate(value)) {
      this.context.appendFilterExpression(field + " " + this.OPERATOR[operator] + " datetime'" + this.toOdataLocalDate(value) + "'");
    } else {
      this.context.appendFilterExpression(field + " " + this.OPERATOR[operator] + " '" + value + "'");
    }
    return this;
  }

  /**
     * Define your own filter with a string when using complex logical expression
     *
     * @param {string} odataFilterExpression
     * @returns {ODataManagerService}
     */
  public customFilter(odataFilterExpression: string): ODataManagerService {
    this.context.appendFilterExpression(odataFilterExpression);
    return this;
  }

  /**
     * Expand for deep or flat entities
     * @param {string} entityNames
     * @returns {ODataManagerService}
     */
  public expand(entityNames: string): ODataManagerService {
    // DEEP EXPAND = "Product/Category/.."
    // FLAT EXPAND = "Product,Supplier,.."
    this.context.expand = entityNames;
    return this;
  }

  /**
     * Maps OData response to a generic class type
     * @param {Response} response
     * @param {{new() => T}} Type
     * @returns {T[]}
     */
  public mapResponse<T>(response: any, Type: { new(): T }): T[] {
    let resultsTyped: T[] = new Array(0);
    let payload = response;

    // Is response not in json format?
    if (response instanceof Response) {
      payload = response.json();
    }

    // OData response first level
    if (payload.hasOwnProperty("d")) {
      payload = payload.d;
    }

    // Array or Single Object?
    const results = payload.hasOwnProperty("results") ? payload.results : [payload];

    // map each entry to a instance of the generic class type <T>
    results.forEach((entry) => {
      const obj = new Type();

      try {
        Object.getOwnPropertyNames(obj).forEach((propName: string) => {
          if (this.context.expand && (propName === "TechInstallationSet" || propName === "TechPowerAssignedSet")) {
            if (propName === "TechInstallationSet") {
              obj[propName] = this.mapResponse<TechInstallationSet>(entry[propName], TechInstallationSet);
            } else if (propName === "TechPowerAssignedSet") {
              obj[propName] = this.mapResponse<TechPowerAssignedSet>(entry[propName], TechPowerAssignedSet);
            }
          } else if (entry[propName] !== undefined && entry[propName] !== null) {
            const valueParsed: any = this.parsetoInternalType(entry[propName], typeof obj[propName]);
            const pattern = new RegExp("/Date\\(-?[0-9]+\\)/");
            if (pattern.test(valueParsed)) {
              obj[propName] = propName.endsWith("Timestamp") ? this.fromODataDate(valueParsed, false) : this.fromODataDate(valueParsed, true);
            } else {
              obj[propName] = valueParsed;
            }
          }
        },
        );
        resultsTyped.push(obj);
      } catch (error) {
      }
    });
    return resultsTyped;
  }

  /**
     * Utils function - Returns local odata datetime as date  "2017-10-22T22:00:00.000"
     * @param {Date} date
     * @returns {string}
     */
  public toOdataLocalDate(date: Date): string {
    let dateString = null;
    if (moment.isDate(date)) {
      dateString = moment(date).format("YYYY-MM-DDT00:00:00.000");
    }
    return dateString;
  }

  /**
     * Utils function - Returns odata datetime as "2017-07-31T23:59:59.999"
     * @param {Date} date
     * @returns {string}
     */
  public toOdataLocalDateTime(date: Date): string {
    let dateTimeString = "";
    if (moment.isDate(date)) {
      dateTimeString = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    return dateTimeString;
  }

  /**
     * Utils function - converts an odata date to a javascript Date object
     * SAP Date: SAP date sometimes have an UTC-Offset failure because the time is missing
     * it always shows 00:00:00 --> UTC-Offset correction applied here
     * Most of the times we need only the date without time. therefore we use correction in most cases
     * @param odataDate
     * @param autocorrect
     * @returns {string}
     */
  public fromODataDate(odataDate: string, autocorrect: boolean): Date {
    if (!odataDate) {
      return null;
    }
    const timestamp = odataDate.replace("/Date(", "").replace(")/", "");
    const localMoment: Moment = moment(parseInt(timestamp, 10));

    // When SAP is sending a Date as DateTime then a Offset Correcton is needed by the current offset time !!!
    if (autocorrect) {
      localMoment.startOf("day");
    }

    return localMoment.toDate();
  }

  /**
     * This method create the query for the odata request
     * @param {ODataContext} context
     * @returns {string}
     */
  private createQueryFromContext(requestMethod: any, context: ODataContext): string {
    let query = "";
    if (!context) {
      throw new Error("Odata Context is not initialised yet!");
    }
    // Service Path
    query += context.servicePath;

    // Requested Entity
    query += context.entity;

    // Get Single Request
    if (context.single) {
      query += context.single;
    }

    // GET Only
    if (requestMethod === RequestMethod.Get) {
      // Format
      query += "?$format=json"; // Do not use with PUT or POST etc..

      // Filters
      context.filters.forEach((expression, index) => {
        query += index === 0 ? "&$filter=" + expression : " and " + expression;
      });

      // Expand
      if (context.expand) {
        query += "&$expand=" + context.expand;
      }

      // Query Parameters for Get Request
      if (context.queryParams && Object.keys(context.queryParams).length) {
        for (const key of Object.keys(context.queryParams)) {
          query += "&" + key + "=";
          // eslint-disable-next-line max-len
          // query += (_.isBoolean(context.queryParams[key]) || _.isNumber(context.queryParams[key])) ? context.queryParams[key] : "'" + context.queryParams[key] + "'";
          query += context.queryParams[key];
        }
      }
    }

    return query;
  }

  /**
     * Primitive Type Parser from external to internal
     * @param inputValue
     * @param {string} targetType
     * @returns {any}
     */
  private parsetoInternalType(inputValue: any, targetType: string): any {
    if (inputValue === undefined || inputValue === null) {
      return null;
    }
    switch (targetType) {
      case "string":
        return inputValue.toString();
      case "number":
        return parseFloat(inputValue);
      case "boolean":
        return inputValue === true || inputValue === "true";
      default:
        return inputValue;
    }
  }
}
