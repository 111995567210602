import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, ReplaySubject, Subject, zip } from "rxjs";
import { map } from "rxjs/operators";
import { InstallationType } from "../model/enum/installation-type";
import { ContractAccountSet } from "../model/service-netz/contract-account-set";
import { PremiseSet } from "../model/service-netz/premise-set";
import { TechInstallationSet } from "../model/service-netz/tech-installation-set";
import { ODataManagerService } from "./odata/odata-manager.service";

@Injectable()
export class ContractService {

  private contractAccountSet$: Subject<ContractAccountSet[]> = new ReplaySubject(1);

  private premiseSet$: Subject<PremiseSet[]> = new ReplaySubject(1);

  private techInstallationSet$: Subject<TechInstallationSet[]> = new ReplaySubject(1);

  constructor(private odataManager: ODataManagerService,
    private configService: BfcConfigurationService) {
    this.initializeData();
  }

  protected initializeData() {
    zip(
      this.odataManager.createContext(this.configService.configuration.sapApiUrls.servicesNetz).entity("ContractAccountSet").query().pipe(
        map(respone => this.odataManager.mapResponse(respone, ContractAccountSet),
        ),
      ),
      this.odataManager.createContext(this.configService.configuration.sapApiUrls.servicesNetz).entity("PremiseSet").expand("TechInstallationSet/TechPowerAssignedSet").query().pipe(
        map(respone => this.odataManager.mapResponse(respone, PremiseSet),
        ),
      ),
    ).subscribe(contractAccountSet => {
      this.contractAccountSet$.next(contractAccountSet[0]);
      let premiseSets = contractAccountSet[1];
      this.premiseSet$.next(premiseSets);
      let techInstallationSets = premiseSets.map(
        premises => {
          return premises.TechInstallationSet;
        },
      ).reduce((p, c) => [].concat(p, c));
      this.techInstallationSet$.next(techInstallationSets);
    });
  }

  getContractAccountSet$(): Observable<ContractAccountSet[]> {
    return this.contractAccountSet$.asObservable();
  }

  getPremiseSet$(): Observable<PremiseSet[]> {
    return this.premiseSet$.asObservable();
  }

  getTechinstallationSetForPremise$(): Observable<TechInstallationSet[]> {
    return this.techInstallationSet$.asObservable();
  }

  /**
   * Filter existing set based on given InstallationType ENUM.
   * @param {Observable<TechInstallationSet[]>} techInstallationSet$
   * @param {InstallationType} type
   * @returns {Observable<TechInstallationSet[]>}
   */
  filterTechInstallationSet$(
    techInstallationSet$: Observable<TechInstallationSet[]>,
    type: InstallationType,
  ): Observable<TechInstallationSet[]> {
    return techInstallationSet$.pipe(
      map(techInsts => {
        switch (type) {
          case InstallationType.CONSUMPTION_INSTALLATION:
            return techInsts.filter(techInst => techInst.IsConsumption && techInst.UiGroupID === "INST");
          case InstallationType.CONSUMPTION_REQUEST:
            return techInsts.filter(techInst => techInst.IsConsumption && techInst.UiGroupID === "TAG");
          case InstallationType.PRODUCTION_INSTALLATION:
            return techInsts.filter(techInst => !techInst.IsConsumption && techInst.UiGroupID === "INST");
          case InstallationType.PRODUCTION_REQUEST:
            return techInsts.filter(techInst => !techInst.IsConsumption && techInst.UiGroupID === "TAG");
        }
      }),
    );
  }

  /**
   * Filter existing set based on given InstallationType ENUM.
   * @param { TechInstallationSet[]} techInstallationSet
   * @param {InstallationType} type
   * @returns {TechInstallationSet[]}
   */
  filterTechInstallationSet(
    techInstallationSet: TechInstallationSet[],
    type: InstallationType,
    premiseId: string,
  ): TechInstallationSet[] {
    switch (type) {
      case InstallationType.CONSUMPTION_INSTALLATION:
        return techInstallationSet.filter(techInst => techInst.IsConsumption && techInst.UiGroupID === "INST" && techInst.PremiseID === premiseId);
      case InstallationType.CONSUMPTION_REQUEST:
        return techInstallationSet.filter(techInst => techInst.IsConsumption && techInst.UiGroupID === "TAG" && techInst.PremiseID === premiseId);
      case InstallationType.PRODUCTION_INSTALLATION:
        return techInstallationSet.filter(techInst => !techInst.IsConsumption && techInst.UiGroupID === "INST" && techInst.PremiseID === premiseId);
      case InstallationType.PRODUCTION_REQUEST:
        return techInstallationSet.filter(techInst => !techInst.IsConsumption && techInst.UiGroupID === "TAG" && techInst.PremiseID === premiseId);
    }
  }

}