import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { FingerprintService } from "./fingerprint.service";
import { switchMap } from "rxjs/operators";

@Injectable()
export class EmailService {

  private headers: HttpHeaders | undefined;

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient,
    private fingerprintService: FingerprintService) {
  }

  sendEmail(userEmail: string, installerEmail: string, subject: string, pdf: string): Observable<void[]> {
    const url = `${this.configurationService.configuration.apiUrl}/email`;
    this.headers = new HttpHeaders();

    return this.fingerprintService.getFingerprintHash().pipe(
      switchMap(hash => {
        this.headers = this.headers!.set("Fingerprint", hash);

        let options = {
          headers: this.headers,
        };
        return this.httpClient.post<void[]>(url,
          { userEmail: userEmail, installerEmail: installerEmail, subject: subject, attachment: pdf }, options);
      }));
  }

}
