import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ColorBoxLayoutComponent } from "./color-box-layout/color-box-layout.component";

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
  ],
  exports: [
    ColorBoxLayoutComponent,
  ],
  declarations: [
    ColorBoxLayoutComponent,
  ],
  providers: [],
})
export class SharedModule {
}
