export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Netzanschluss",
  "GC_TITLE": "Netzanschluss",
  "GC_FILTER": {
    "CONTRACT": "Vertragskonto",
    "CONTACT": "Kontaktperson",
    "BUILDING": "Gebäude/Verbrauchsstelle",
    "METER_NUMBER": "Zählernummer",
  },
  "GC_INSTALLATION": {
    "1": "Blockheizkraftwerk",
    "2": "Boilergruppe 1-3",
    "3": "Elektroheizung",
    "4": "Wärmepumpe",
    "5": "Wind",
    "6": "Solar",
    "7": "Biogas",
    "8": "Lift",
    "9": "Motoren",
    "10": "Wasser",
    "": "Installation",
  },
  "GC_TILES": {
    "EMPTY_DESCRIPTION": "Aufgrund von speziellen Netzverhältnissen kann keine automatische Berechnung für diesen Anschlusspunkt angegeben werden. Bitte erfassen Sie hierfür ein technisches Anschlussgesuch.",
    "PREPOSITIONAL_PHRASE_GENERAL": "dem",
    "PREPOSITIONAL_PHRASE_PERSONAL": "Ihrem",
    "PHOTOVOLTAIC": {
      "TITLE": "Photovoltaikanlage",
      "TEXT": "Mögliche Einspeiseleistung",
      "UNIT": "kVA",
      "DESCRIPTION": "An {{prepositionalPhrase}} Netzanschluss kann am {{today}} eine 3&#8209;phasige Photovoltaikanlage von maximal {{value}} Wechselrichterleistung mit entsprechender Blindleistungs-Parametrierung angeschlossen werden.",
      "DESCRIPTION_PDF": "An Ihrem Netzanschluss kann am {{today}} eine 3-phasige Photovoltaikanlage von maximal {{value}} Wechselrichterleistung mit entsprechender Blindleistungs-Parametrierung angeschlossen werden.",
    },
    "HEATPUMP": {
      "TITLE": "Wärmepumpe",
      "TEXT": "Maximaler Anlaufstrom",
      "UNIT": "A",
      "DESCRIPTION": "An {{prepositionalPhrase}} Netzanschluss können am {{today}} 3&#8209;phasige Geräte mit einem Anlaufstrom von maximal {{value}} angeschlossen werden.",
      "DESCRIPTION_PDF": "An Ihrem Netzanschluss können am {{today}} 3-phasige Geräte mit einem Anlaufstrom von maximal {{value}} angeschlossen werden.",
    },
    "ELECTROMOBILITY": {
      "TITLE": "Elektromobilität",
      "TEXT": "Leistung Elektroladestation",
      "UNIT": "kW",
      "DESCRIPTION": "An {{prepositionalPhrase}} Netzanschluss kann am {{today}} eine Elektroladestation von {{value}} angeschlossen werden. Eine grössere Leistung als {{value2}} bedingt eine erweiterte Prüfung durch die BKW.",
      "DESCRIPTION_PDF": "An Ihrem Netzanschluss kann am {{today}} eine Elektroladestation von {{value}} angeschlossen werden. Eine grössere Leistung als {{value2}} bedingt eine erweiterte Prüfung durch die BKW.",
    },
  },
  "GC_CAPACITY": {
    "TITLE": "Netzanschlussgesuch Kapazitätsabfrage",
    "INTRODUCTION_INSTALLATION": "Die folgenden tagesaktuellen Informationen dienen Ihnen bei der Planung Ihres Installationsvorhabens. Für eine verbindliche Auskunft ist der BKW ein technisches Anschlussgesuch einzureichen.",
    "GENERAL_INFORMATION": "Diese oben erläuterten Werte können voraussichtlich im Rahmen der Einreichung eines technischen Anschlussgesuches von der BKW ohne Ausbauarbeiten am Verteilnetz zugesichert werden. \n\n Die Einreichung des technischen Anschlussgesuches und der darauffolgenden Installationsanzeige erfolgt vor der Installationsausführung.",
    "METER_NUMBER_INPUT": "Zählernummer",
    "SEARCH_BUTTON": "Anfrage",
    "NO_METER_SELECTED": "Keine Ergebnisse gefunden",
    "REQUEST_FAILED_RATE_LIMIT": "Um Massenabfragen zu verhindern, haben wir eine Limite pro User pro Tag eingebaut. Diese Limite haben Sie erreicht. Bitte versuchen Sie es morgen wieder.",
  },
  "GC_VIEW": {
    "INTRODUCTION_INSTALLATION": "Die folgenden tagesaktuellen Informationen dienen Ihnen und Ihrem Fachpartner bei der Planung Ihres Installationsvorhabens. Für eine verbindliche Auskunft ist der BKW ein technisches Anschlussgesuch einzureichen. Ihr Fachpartner unterstützt Sie dabei. ",
    "FURTHER_INFORMATION": "Weiterführende Informationen",
    "GENERAL_INFORMATION": "Diese oben erläuterten Werte können voraussichtlich im Rahmen der Einreichung eines technischen Anschlussgesuches durch Ihren Fachpartner von der BKW ohne Ausbauarbeiten am Verteilnetz zugesichert werden. \n\n Die Einreichung des technischen Anschlussgesuches und der darauffolgenden Installationsanzeige durch den Fachpartner erfolgt vor der Installationsausführung.",
    "FURTHER_TECHNICAL_INFORMATION": "Weiterführende technische Angaben",
    "CONNECTION_OBJECT_NUMBER": "Anschlussobjektnummer",
    "NET_CABLE": "Netzanschlusskabel",
    "ELECTRO_CONTROL": "Fälligkeit der nächsten Elektrokontrolle",
    "HOME_CONNECTION": "Hausanschluss Sicherung",
    "PERFORMANCE": "Vereinbarte Leistung",
    "FORWARDING_INFO": "Weiterleiten der Netzanschluss-Informationen ",
    "FORWARDING_INFO_HINT": "Es besteht für Sie die Möglichkeit, die Informationen «rund um Ihren Netzanschluss» an einen von Ihnen gewünschten Fachpartner (konzessionierter Elektroinstallateur) weiterzuleiten.",
    "PRINT": "Datenblatt als PDF",
    "CONSUMER_INSTALLATION_TITLE": "Bewilligungspflichtige Energieverbraucher",
    "PRODUCER_INSTALLATION_TITLE": "Energieerzeuger",
    "INSTALLATION_HEADER": "Ihre Installationen",
    "REQUEST_HEADER": "Ihre Anschlussgesuche / Meldungen",
    "OBJECTID": "Equipment",
    "STATUS": "Status",
    "INSTALLATION_TYPE": "Typ",
    "INSTALLATION_DATE": "Datum der Inbetriebnahme",
    "INSTALLATION_DATE_REQUEST": "Datum des bewilligten Anschlussgesuchs",
    "REQUEST_VALID_UNTIL": "Reservierung gültig bis",
    "MAX_CONSUMPTION": "Bewilligter maximaler Anlaufstrom",
    "MAX_CONSUMPTION_REQUEST": "Maximaler Anlaufstrom",
    "MAX_PRODUCTION": "Einspeiseleistung",
    "MAX_PRODUCTION_REQUEST": "Maximale Einspeiseleistung",
    "INSTALLATION_STATE": {
      "01": "Technisches Anschlussgesuch in Bearbeitung",
      "02": "Technisches Anschlussgesuch beantwortet",
      "03": "Installationsanzeige in Bearbeitung",
      "04": "Installationsanzeige bearbeitet",
      "05": "Fertigstellungsanzeige in Bearbeitung",
      "06": "Fertigstellungsanzeige bearbeitet",
      "07": "Sicherheitsnachweis in Bearbeitung",
      "08": "Installationsanzeige abgeschlossen",
      "98": "Technisches Anschlussgesuch in Bearbeitung",
      "99": "Installation in Betrieb",
    },
    "CAPACITY": {
      "TITLE_CONSUMPTION": "Zulässiger maximaler Anlaufstrom",
      "INTRO_CONSUMPTION": "Am {{today}} verfügbarer maximaler dreiphasiger Anlaufstrom an ihrem Netzanschluss für Verbraucher (z.B. Wärmepumpen oder Motoren). Dieser maximale Anlaufstrom kann voraussichtlich im Rahmen der Einreichung eines technischen Anschlussgesuches zugesichert werden.",
      "TITLE_PRODUCTION": "Maximale zusätzlich installierbare Einspeiseleistung (ohne Massnahmen)",
      "INTRO_PRODUCTION": "Am {{today}} noch anschliessbare maximale Einspeiseleistung mit entsprechender Blindleistungsparametrierung von dreiphasigen Energieerzeugungsanlagen an ihrem Netzanschluss. Diese Einspeiseleistung kann voraussichtlich im Rahmen der Einreichung eines technischen Anschlussgesuches zugesichert werden.",
      "MAX_CONSUMPTION": "Zulässiger maximaler Anlaufstrom",
      "MAX_CONSUMPTION_UNIT": "A",
      "MAX_PRODUCTION": "Maximale zusätzlich installierbare Einspeiseleistung (ohne Massnahmen)",
      "MAX_PRODUCTION_UNIT": "kVA",
      "MAX_CONSUMPTION_ZERO": "Der Anschluss einer zusätzlichen Installation erfordert in jedem Fall einen vorgängigen Netzausbau.",
      "MAX_PRODUCTION_ZERO": "Der Anschluss einer neuen Energieerzeugungsanlage erfordert in jedem Fall einen vorgängigen Netzausbau.",
      "MAX_PRODUCTION_SOLPOT": "Basierend auf Angaben des BFE (Sonnendach.ch) gehen wir für unsere Planungen davon aus, dass auf der Dachfläche des Gebäudes an dieser Adresse eine Photovoltaik-Anlage mit einer installierten Leistung von maximal {{solpot}} kVA erstellt werden kann.",
    },
    "COMPARISON": {
      "TITLE": "Vergleichsgrösse",
      "20-30": "Der verfügbare Anlaufstrom des Netzes reicht beispielsweie für eine Wärmepumpe von 1 bis 2 Wohneinheiten.",
      "30-80": "Der verfügbare Anlaufstrom des Netzes reicht beispielsweie für eine Wärmepumpe von 3 bis 10 Wohneinheiten.",
      "80-plus": "Der verfügbare Anlaufstrom des Netzes reicht beispielsweie für eine Wärmepumpe von mehr als 10 Wohneinheiten.",
    },
  },
  "GC_TECHPOWER": {
    "ZNA_LEIST_EFF_INST": "Effektive installierte Leistung",
    "ZNA_LEIST_AC": "Installierte Leistung",
    "ZNA_BOILERGRU_1_KW": "Leistung",
    "ZNA_BOILERGRU_2_KW": "Leistung",
    "ZNA_ELEKTROHEIZUNG_KW": "Installierte Leistung",
    "ZNA_WAPU_MAX_ANLAUFSTROM": "Maximaler Anlaufstrom",
    "ZNA_WAPU_LEISTUNG_PNT": "Leistung PNT",
    "ZNA_NETZRUECKWIRKUNG_KW": "Netzrückwirkung Leistung",
  },
  "GC_REQUEST": {
    "INTENTION_TITLE": "Möchten Sie eine Energieerzeugungsanlage, eine Wärmepumpe oder einen Lift installieren?",
    "INTENTION_DESCRIPTION_INTRO": "Für den Anschluss neuer Energieerzeugungsanlagen (EEA) oder spezieller elektrischer Verbraucher (Wärmepumpe, Lift etc.) an ihrem bestehenden Netzanschluss müssen sie oder ein von ihnen beauftragter Installateur ein technisches Anschlussgesuch (TAG) bei der {{companyName1}} einreichen. Basierend auf diesem Gesuch prüft die {{companyName2}}, ob die technischen Rahmenbedingungen (insbesondere Spannungshaltung / -erhöhung und Anlaufströme) eingehalten werden oder ob die {{companyName3}} das Netz verstärken muss bevor die EEA oder der Verbraucher angeschlossen werden kann. Sollten Netzverstärkungen notwendig sein, werden ihnen zudem die allfällig für sie entstehenden Kosten mitgeteilt.",
    "INTENTION_MORE": "mehr anzeigen",
    "INTENTION_ACTION": "TAG einreichen",
  },
  "PDF": {
    "TITLE": "Netzanschluss",
    "VERSION": "Stand der Daten",
    "DATE": "Datum",
    "REQUEST_VALID_UNTIL": "gültig bis ",
    "MAX": "Max",
    "INSTALLATION_STATE": {
      "01": "TAG in Bearbeitung",
      "02": "TAG beantwortet",
      "03": "IA in Bearbeitung",
      "04": "IA bearbeitet",
      "05": "FA in Bearbeitung",
      "06": "FA bearbeitet",
      "07": "SiNA in Bearbeitung",
      "08": "IA abgeschlossen",
      "98": "TAG in Bearbeitung",
      "99": "in Betrieb",
    },
  },
  "INSTALLER_COMPONENT": {
    "ERROR_MAIL": "Das E-Mail konnte nicht versendet werden. Versuchen Sie es bitte erneut und prüfen Sie die Eingabe.",
    "SUCCESS_MAIL": "Das E-Mail wurde erfolgreich dem von Ihnen eingefügten Empfänger zugestellt. Sie haben zusätzlich eine Kopie erhalten.",
    "SENT_INSTALLER_DESCRIPTION": "Senden Sie die Angaben zu Ihrem Netzanschluss an Ihren Fachpartner <br /> oder eine beliebige E-Mail-Adresse",
    "CHOOSE_INSTALLER": "Wählen Sie einen Fachpartner ",
    "YOUR_INSTALLER": "Ihr Fachpartner: ",
    "EMAIL_DECLARATION": "Versenden Sie die Angaben zu Ihrem Netzanschluss an eine beliebige E-Mail-Adresse ",
    "NO_EMAIL": "Der ausgewählte Fachpartner hat keine E-Mail hinterlegt.",
    "DATAPAPER": "Datenblatt",
    "DATAPAPER_DESCRIPTION": "Die Zusammenfassung zu Ihrem Netzanschluss können Sie hier herunterladen:",
    "YOUR_GRIDCONNECTION": "Ihr Netzanschluss",
    "CLOSE": "Ansicht schliessen",
    "LABELS": {
      "NAME": "Name",
      "FIRST_NAME": "Vorname",
      "STREET": "Strasse",
      "ZIP": "PLZ",
      "CITY": "Ort",
      "PHONE": "Tel",
      "EMAIL": "E-Mail",
    },
    "FORM": {
      "SENT": "Senden",
      "PLACEHOLDERS": {
        "EMAIL": "E-Mail",
      },
    },
  },
  "VALIDATIONS": {
    "EMAIL_INVALID": "Ungültige E-Mail-Adresse",
  },
  "SELF_AUTHORIZE": {
    "TITLE": "Selbstregistrierung für die Kapazitätsabfrage am Netzanschluss",
    "DESCRIPTION": "Sie haben den Dienst für Kapazitätsanfragen noch nicht benutzt. " +
        "Mit der Registrierung stimmen Sie den Nutzungsbedingungen des Dienstes zu",
    "AGB_CHECKBOX": "Ich habe die <a target='_blank' href='https://www.bkw.ch/fileadmin/user_upload/06_Footer/AGB/Strom/Netzanschluss_und_Netznutzung/Nutzungsbestimmung.pdf'>Nutzungsbedingungen Kapazitätsabfrage</a> gelesen und stimme diesen ausdrücklich zu.",
    "REGISTER_BUTTON": "Registrieren",
  },
};
