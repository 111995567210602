import { AppConfiguration } from "../configuration";

export const P_ENV: AppConfiguration = {
  contactUrl: "https://connect.bkw.ch/contact?environment=GRIDCONNECTION&from=https://gridconnection.bkw.ch",
  sapApiUrls: {
    servicesNetz: "https://my.bkw.ch/etility/proxy/servicesNetz/",
  },
  apiUrl: "https://api-gridconnection.bkw.ch",
  gridServiceApiUrl: "https://api.bkw.ch/api/b2b/OnePortal/ConnectionRequest/v1/MaxPIS",
  installerApiUrl: "https://api-esti.bkw.ch/installers",
};
