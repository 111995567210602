import { AppConfiguration } from "../configuration";

export const GENERAL_ENV: AppConfiguration = {
  companyName: "BKW",
  pdfDateFormat: "DD.MM.YYYY",
  googleAnalyticsTrackingId: "UA-58712110-1",
  googleTagManagerContainerId: "GTM-N4VBJZH",
  gridConnectionRequestUrl: "https://webapp.elektroform.ch/0/vnb/none/Authentication/Login",
  roleMyBkw: "ROLE_MYBKW",
  roleCapacityRequest: "ROLE_GRIDCONNECTION_CAPACITY_REQUEST",
};
