import { AppConfiguration } from "../configuration";

export const LOCAL_ENV: AppConfiguration = {
  contactUrl: "https://connect-t.bkw.ch/contact?environment=GRIDCONNECTION&from=http://localhost:9001",
  sapApiUrls: {
    servicesNetz: "https://my-t.bkw.ch/etility/servicesNetz/",
  },
  apiUrl: "https://api-gridconnection-t.bkw.ch",
  // apiUrl: "http://localhost:8080",
  gridServiceApiUrl: "https://api-t.bkw.ch/apit/b2b/OnePortal/ConnectionRequest/v1/MaxPIS-t",
  installerApiUrl: "https://api-esti-t.bkw.ch/installers",
};
