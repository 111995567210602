export class ContractAccountSet {
  ContractAccountID: string = "";

  BillToAccountAddressID: string = "";

  AccountAddressID: string = "";

  BillToAccountID: string = "";

  AccountID: string = "";

  BudgetBillingProcedure: string = "";

  CountryID: string = "";

  CorrespondenceVariant: string = "";

  Description: string = "";

  IncomingPaymentBankaccountID: string = "";

  IncomingPaymentMethodID: string = "";

  IncomingPaymentPaymentcardID: string = "";

  OutgoingPaymentBankaccountID: string = "";

  OutgoingPaymentMethodID: string = "";

  OutgoingPaymentPaymentcardID: string = "";

  Consolidator: string = "";

  FlagInactiv: boolean = false;

  EndDate: Date = undefined;

  StartDate: Date = undefined;

  FlagInstallmentPlanPossible: boolean = false;

  DefaultPaymentMethodType: string = "";
}