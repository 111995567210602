export const FR_TRANSLATIONS = {
  "SERVICE_NAME": "Raccordement au réseau",
  "GC_TITLE": "Raccordement au réseau",
  "GC_FILTER": {
    "CONTRACT": "Compte contractuel",
    "CONTACT": "Personne de contact",
    "BUILDING": "Bâtiment/point de consommation",
    "METER_NUMBER" : "Numéro du compteur",
  },
  "GC_INSTALLATION": {
    "1": "Centrale de chauffage",
    "2": "Groupe chauffe-eau 1-3",
    "3": "Chauffage électrique",
    "4": "Pompe à chaleur",
    "5": "Eolien",
    "6": "Solaire",
    "7": "Biogaz",
    "8": "Ascenseur",
    "9": "Moteurs",
    "10": "Eau",
    "": "Installation",
  },

  "GC_TILES": {
    "EMPTY_DESCRIPTION": "En raison de conditions de réseau particulières, aucun calcul automatique ne peut être indiqué pour ce point de raccordement. Veuillez saisir une demande de raccordement technique à cet effet.",
    "PREPOSITIONAL_PHRASE_GENERAL": "ce",
    "PREPOSITIONAL_PHRASE_PERSONAL": "votre",
    "PHOTOVOLTAIC": {
      "TITLE": "Installation photovoltaïque ",
      "TEXT": "Capacité d’injection maximale possible ",
      "UNIT": "kVA",
      "DESCRIPTION": "Une installation photovoltaïque triphasée d'une puissance maximale de l'onduleur de {{value}}, avec paramétrage de la puissance réactive, peut être raccordée à {{prepositionalPhrase}} raccordement au réseau le {{today}} ",
      "DESCRIPTION_PDF": "Une installation photovoltaïque triphasée d'une puissance maximale de l'onduleur de {{value}}, avec paramétrage de la puissance réactive, peut être raccordée à votre raccordement au réseau le {{today}} ",
    },
    "HEATPUMP": {
      "TITLE" : "Pompe à chaleur",
      "TEXT": "Courant de démarrage maximal ",
      "UNIT": "A",
      "DESCRIPTION": "Vous pouvez raccorder des appareils triphasés avec un courant de démarrage maximal de {{value}} à {{prepositionalPhrase}} raccordement au réseau le {{today}}. ",
      "DESCRIPTION_PDF": "Vous pouvez raccorder des appareils triphasés avec un courant de démarrage maximal de {{value}} à votre raccordement au réseau le {{today}}. ",
    },
    "ELECTROMOBILITY": {
      "TITLE": "Électromobilité",
      "TEXT": "Puissance de la station de recharge électrique ",
      "UNIT": "kW",
      "DESCRIPTION": "Une borne de recharge électrique de {{value}} peut être raccordée à {{prepositionalPhrase}} raccordement au réseau le {{today}}. Une puissance supérieure à 11 kW nécessitera une évaluation approfondie de la part de BKW. ",
      "DESCRIPTION_PDF": "Une borne de recharge électrique de {{value}} peut être raccordée à votre raccordement au réseau le {{today}}. Une puissance supérieure à 11 kW nécessitera une évaluation approfondie de la part de BKW. ",
    },
  },
  "GC_CAPACITY": {
    "TITLE": "Demande de raccordement au réseau",
    "METER_NUMBER_INPUT": "Numéro du compteur",
    "INTRODUCTION_INSTALLATION": "Les informations suivantes, mises à jour quotidiennement, vous aideront, à planifier votre projet d’installation. Pour obtenir une réponse ferme, il est nécessaire de soumettre une demande de raccordement technique à BKW.",
    "GENERAL_INFORMATION": "Les valeurs mentionnées ci-dessus peuvent vraisemblablement être confirmées dans le cadre de la soumission d'une demande de raccordement technique auprès de BKW, sans nécessiter de travaux de renforcement du réseau de distribution. La demande de raccordement technique ainsi que l’avis d'installation devront nous être soumis par le partenaire spécialisé avant la réalisation de l'installation. ",
    "SEARCH_BUTTON": "Rechercher",
    "NO_METER_SELECTED": "Aucun résultat trouvé",
    "REQUEST_FAILED_RATE_LIMIT": "Afin d'éviter les requêtes massives, nous avons fixé une limite par utilisateur et par jour. Vous avez atteint cette limite. Veuillez réessayer demain.",
  },
  "GC_VIEW": {
    "INTRODUCTION_INSTALLATION": "Les informations suivantes, mises à jour quotidiennement, vous aideront, vous et votre partenaire spécialisé, à planifier votre projet d’installation. Pour obtenir une réponse ferme, il est nécessaire de soumettre une demande de raccordement technique à BKW. Votre partenaire spécialisé pourra vous aider. ",
    "HEATPUMP_DESCRIPTION_PDF": "Vous pouvez raccorder des appareils triphasés avec un courant de démarrage maximal de {{value}} A à votre raccordement au réseau le {{today}}. ",
    "FURTHER_INFORMATION": "Informations complémentaires ",
    "FURTHER_TECHNICAL_INFORMATION": "Informations techniques complémentaires ",
    "GENERAL_INFORMATION": "Les valeurs mentionnées ci-dessus peuvent vraisemblablement être confirmées dans le cadre de la soumission d'une demande de raccordement technique par votre partenaire spécialisé auprès de BKW, sans nécessiter de travaux de renforcement du réseau de distribution. La demande de raccordement technique ainsi que l’avis d'installation devront nous être soumis par le partenaire spécialisé avant la réalisation de l'installation. ",
    "CONNECTION_OBJECT_NUMBER": "Numéro de l’objet de raccordement ",
    "NET_CABLE": "Câble de raccordement au réseau ",
    "ELECTRO_CONTROL": "Échéance du prochain contrôle des installations électriques ",
    "HOME_CONNECTION": "Fusibles de raccordement au réseau ",
    "PERFORMANCE": "Puissance contractuelle ",
    "FORWARDING_INFO": "Transmission des informations de raccordement au réseau ",
    "FORWARDING_INFO_HINT":"Vous avez la possibilité de transmettre les informations \"concernant votre raccordement au réseau\" à un partenaire spécialisé de votre choix (un Installateur électricien possédant une autorisation d'installer) ",
    "PRINT": "Fiche technique au format PDF",
    "CONSUMER_INSTALLATION_TITLE": "Récepteurs d’énergie ",
    "PRODUCER_INSTALLATION_TITLE": "Producteur d’énergie",
    "INSTALLATION_HEADER": "Vos installations",
    "REQUEST_HEADER": "Vos demandes de raccordement / notifications",
    "OBJECTID": "Equipment",
    "STATUS": "Statut",
    "INSTALLATION_TYPE": "Type",
    "INSTALLATION_DATE": "Date de la mise en service",
    "INSTALLATION_DATE_REQUEST": "Date de la demande de raccordement autorisée",
    "REQUEST_VALID_UNTIL": "Réservation valable jusqu'au",
    "MAX_CONSUMPTION": "Courant maximal de démarrage autorisé",
    "MAX_CONSUMPTION_REQUEST": "Courant maximal de démarrage",
    "MAX_PRODUCTION": "Capacité d'injection maximale",
    "MAX_PRODUCTION_REQUEST": "Puissance d’injection maximale",
    "INSTALLATION_STATE": {
      "01": "Demande de raccordement technique en cours de traitement",
      "02": "Demande de raccordement technique traitée",
      "03": "Avis d'installation en cours de traitement",
      "04": "Avis d'installation traité",
      "05": "Avis d'achèvement en cours de traitement",
      "06": "Avis d'achèvement traité",
      "07": "Rapport de sécurité en cours de traitement",
      "08": "Avis d'installation finalisé",
      "98": "Demande de raccordement technique en cours de traitement",
      "99": "Installation en service",
    },
    "CAPACITY": {
      "TITLE_CONSUMPTION": "Courant de démarrage maximal",
      "INTRO_CONSUMPTION": "Au {{today}}, courant démarrage triphasé max. disponible pour votre raccordement au réseau pour des consommateurs. (p.ex. PAC ou moteurs) Ce courant démarrage devrait être confirmé après réception d'une demande de raccordement technique. ",
      "TITLE_PRODUCTION": "Capacité d'injection max. disponible (sans mesures)",
      "INTRO_PRODUCTION": "Au {{today}}, capacité d'injection triphasée max. disponible au niveau de votre raccordement au réseau avec paramétrage de la puissance réactive correspondante. Cette puissance d'injection devrait  être confirmée après réception d'une demande de raccordement technique.",
      "MAX_CONSUMPTION": "Courant de démarrage maximal",
      "MAX_CONSUMPTION_UNIT": "A",
      "MAX_PRODUCTION": "Capacité d'injection max. disponible (sans mesures)",
      "MAX_PRODUCTION_UNIT": "kVA",
      "MAX_CONSUMPTION_ZERO": "Le raccordement d’une installation supplémentaire nécessite dans tous les cas un renforcement du réseau.",
      "MAX_PRODUCTION_ZERO": "Le raccordement d’une nouvelle installation de production d’énergie nécessite dans tous les cas un renforcement du réseau.",
      "MAX_PRODUCTION_SOLPOT": "Selon les indications de l'OFEN (Sonnendach.ch) et sur la base de la surface du toit du bâtiment, une installation photovoltaïque d'une puissance théorique de max. {{solpot}} kVA serait installable à cette adresse.",
    },
    "COMPARISON": {
      "TITLE": "Grandeur de comparaison",
      "20-30": "Le courant de démarrage au niveau du réseau est par exemple suffisant pour une pompe à chaleur pour un à deux logements.",
      "30-80": "Le courant de démarrage au niveau du réseau est par exemple suffisant pour une pompe à chaleur pour trois à dix logements.",
      "80-plus": "Le courant de démarrage au niveau du réseau est par exemple suffisant pour une pompe à chaleur pour plus de dix logements.",
    },
  },
  "GC_REQUEST": {
    "INTENTION_TITLE": "Voulez-vous mettre en place une installation de production d’énergie, une pompe à chaleur ou un ascenseur?",
    "INTENTION_DESCRIPTION_INTRO": "Pour pouvoir ajouter de nouvelles installations de production d’énergie (IPE) ou des consommateurs électriques spéciaux (pompe à chaleur, ascenseur, etc.) au niveau de votre raccordement actuel au réseau, vous-même ou un installateur que vous aurez mandaté devez déposer une demande de raccordement technique (DRT) auprès de {{companyName1}}. A partir de cette demande, {{companyName2}} vérifie si les conditions-cadres techniques (en particulier le maintien/l’augmentation de la tension et les courants de démarrage) le permettent ou si {{companyName3}} doit renforcer le réseau avant le raccordement de l’IPE ou du poste consommateur. Si un renforcement du réseau s'avère nécessaire, {{companyName4}} vous informe des coûts à votre charge.",
    "INTENTION_MORE": "Afficher plus",
    "INTENTION_ACTION": "Soumettre DRT",
  },
  "PDF": {
    "TITLE": "Raccordement au réseau",
    "VERSION": "Statut des données",
    "DATE": "Date",
    "REQUEST_VALID_UNTIL": "valable jusqu'au ",
    "MAX": "Max",
    "INSTALLATION_STATE": {
      "01": "TAG in Bearbeitung (TODO)",
      "02": "TAG beantwortet (TODO)",
      "03": "IA in Bearbeitung (TODO)",
      "04": "IA bearbeitet (TODO)",
      "05": "FA in Bearbeitung (TODO)",
      "06": "FA bearbeitet (TODO)",
      "07": "SiNA in Bearbeitung (TODO)",
      "08": "IA abgeschlossen (TODO)",
      "98": "TAG in Bearbeitung (TODO)",
      "99": "in Betrieb (TODO)",
    },
  },
  "INSTALLER_COMPONENT" : {
    "ERROR_MAIL": "L'e-mail n'a pas pu être envoyé. Veuillez réessayer et vérifier la saisie.",
    "SUCCESS_MAIL": "L'e-mail a été envoyé avec succès au destinataire que vous avez inséré. Voici une copie pour votre information.",
    "SENT_INSTALLER_DESCRIPTION": "Envoyez les informations relatives à votre raccordement au réseau à votre partenaire spécialisé <br /> ou à une adresse e-mail de votre choix ",
    "CHOOSE_INSTALLER": "Sélectionnez un partenaire spécialisé ",
    "YOUR_INSTALLER": "Votre partenaire spécialisé:",
    "EMAIL_DECLARATION" : "Envoyez les informations relatives à votre raccordement au réseau à une adresse e-mail de votre choix ",
    "NO_EMAIL": "Le partenaire spécialisé sélectionné n’a pas renseigné d’adresse e-mail. ",
    "DATAPAPER": "Fiche technique ",
    "DATAPAPER_DESCRIPTION": "Vous pouvez télécharger le résumé de votre raccordement au réseau ici: ",
    "YOUR_GRIDCONNECTION": "Raccordement au réseau",
    "CLOSE": "Fermer la vue",
    "LABELS": {
      "NAME": "Nom",
      "FIRST_NAME": "Prenom",
      "STREET": "Rue",
      "ZIP": "NPA",
      "CITY": "Lie",
      "PHONE": "Tel",
      "EMAIL": "E-Mail",
    },
    "FORM": {
      "SENT": "Envoyer ",
      "PLACEHOLDERS": {
        "EMAIL": "E-Mail",
      },
    },
  },
  "VALIDATIONS": {
    "EMAIL_INVALID": "Adresse e-mail non valide",
  },
  "SELF_AUTHORIZE": {
    "TITLE": "Enregistrement pour la demande de raccordement au réseau",
    "DESCRIPTION": "Vous n'avez pas encore utilisé le service de demande de capacité. En vous enregistrant, vous acceptez les conditions d'utilisation du service",
    "AGB_CHECKBOX": "J'ai lu et accepte les <a target='_blank' href='https://www.bkw.ch/fileadmin/user_upload/06_Footer/AGB/Strom/Netzanschluss_und_Netznutzung/Conditions-d-utilisation.pdf'>conditions générales de vente \"Demande de capacité\"</a>.",
    "REGISTER_BUTTON": "Enregistrer",
  },
};
