import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { InitializerProvider } from "./initialization/initializer-provider";
import { initalizerFactory } from "./initialization/initializer-factory";
import { GridService } from "./services/grid.service";
import { ContractService } from "./services/contract.service";
import { HttpClientModule } from "@angular/common/http";
import { ODataManagerService } from "./services/odata/odata-manager.service";
import { BfcTranslationService } from "@bfl/components/translation";
import { PdfExportService } from "./services/pdfexport.service";
import { BfcNotificationService } from "@bfl/components/notification";
import { FingerprintService } from "./services/fingerprint.service";
import { EmptyDefaultPipe } from "../common/empty-default-pipe/empty-default.pipe";

@NgModule({
  imports: [
    HttpClientModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    ODataManagerService,
    GridService,
    ContractService,
    BfcTranslationService,
    BfcNotificationService,
    PdfExportService,
    FingerprintService,
    EmptyDefaultPipe,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
