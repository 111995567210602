import { AddressInfo } from "./address-info";
import { TechInstallationSet } from "./tech-installation-set";

export  class PremiseSet {
  PremiseID: string = "";

  ConnObjID: string = "";

  PremiseTypeID: string = "";

  MeterNumber: string = "";

  Regiogroup: string = "";

  Devices: string = "";

  ObjectDescription: string = "";

  ContractAccountID: string = "";

  SupplyPossible: boolean = false;

  IsValidForHistory: boolean = false;

  IsValidForMove: boolean = false;

  IsValidForProduct: boolean = false;

  IsActive: boolean = false;

  UsingForEe: boolean = false;

  AnnexPremise: boolean = false;

  AddressInfo: AddressInfo = undefined;

  TechInstallationSet : TechInstallationSet[] = [];

}