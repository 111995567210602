import { Injectable } from "@angular/core";
import * as Fingerprintjs2 from "fingerprintjs2";
import { ReplaySubject } from "rxjs";

@Injectable()
export class FingerprintService {

  private _fingerprintHash$ = new ReplaySubject<any>(1);

  public constructor() {
    setTimeout(() => {
      new (Fingerprintjs2 as any).get((hash: any) => {
        this._fingerprintHash$.next(Fingerprintjs2.x64hash128(hash.join(""), 31));
      });
    }, 500);
  }

  public getFingerprintHash(): ReplaySubject<string> {
    return this._fingerprintHash$;
  }
}
