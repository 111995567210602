import { HttpHeaders } from "@angular/common/http";

export class ODataContext {

  private _entity: string = "";

  private _servicePath: string;

  private _headers: HttpHeaders;

  private _filters: string[] = [];

  private _single: string;

  private _expand: string;

  private _queryParams: object = null; // ZusÃ¤tzliche Query-Parameters fÃ¼r Get Request { field : value }

  constructor(servicePath: string, headers: HttpHeaders) {
    this._servicePath = servicePath;
    this._headers = headers;
  }

  get entity(): string {
    return this._entity;
  }

  set entity(value: string) {
    this._entity = value;
  }

  get servicePath(): string {
    return this._servicePath;
  }

  set servicePath(value: string) {
    this._servicePath = value;
  }

  get headers(): HttpHeaders {
    return this._headers;
  }

  set headers(value: HttpHeaders) {
    this._headers = value;
  }

  get filters(): string[] {
    return this._filters;
  }

  public appendFilterExpression(filterExpression: string) {
    this._filters.push(filterExpression);
  }

  get single(): string {
    return this._single;
  }

  set single(value: string) {
    this._single = value;
  }

  get expand(): string {
    return this._expand;
  }

  set expand(value: string) {
    this._expand = value;
  }

  get queryParams(): object {
    return this._queryParams;
  }

  set queryParams(value: object) {
    this._queryParams = value;
  }
}
