import { TechPowerAssignedSet } from "./tech-power-assigned-set";

export class TechInstallationSet {
  PremiseID: string = "";

  ObjectID: string = "";

  ConnectionObjectID: string = "";

  InstallationTypeID: string = ""; // enum

  IsConsumption: boolean = false;

  UiGroupID: string = "";

  ActivationDate: Date = undefined;

  ReservationDate: Date = undefined;

  RequestDate: Date = undefined;

  PowerAssignedQuantity: string = "";

  PowerAssignedUnit: string = "";

  StatusID: string = "";

  StatusText: string = "";

  NextInspectionDate: Date = undefined;

  TechPowerAssignedSet: TechPowerAssignedSet[] = [];
}