import { AppConfiguration } from "../configuration";

export const Q_ENV: AppConfiguration = {
  contactUrl: "https://connect-q.bkw.ch/contact?environment=GRIDCONNECTION&from=https://gridconnection-q.bkw.ch",
  sapApiUrls: {
    servicesNetz: "https://my-q.bkw.ch/etility/proxy/servicesNetz/",
  },
  apiUrl: "https://api-gridconnection-q.bkw.ch",
  gridServiceApiUrl: "https://api-q.bkw.ch/apiq/b2b/OnePortal/ConnectionRequest/v1/MaxPIS-t",
  installerApiUrl: "https://api-esti-q.bkw.ch/installers",
};
