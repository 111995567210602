<div fxLayout="row">
    <div class="cb-element {{bgStripes ? 'cb-stripes' : ''}}" fxLayout="column">
        <div class="cb-header {{colorClass}}" fxFlex="auto" fxLayout="row" fxLayoutAlign="space-between center">
            <ng-content select="[color-box-header]"></ng-content>
        </div>
        <div class="cb-spike {{colorClass}}"></div>
        <div class="cb-content">
            <ng-content class="cb-content-entry" select="[color-box-content]"></ng-content>
        </div>
    </div>
</div>
